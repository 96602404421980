import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { ShadchanService } from '../services/shadchan.service';
import { Profile } from '../../../../../types/profile';
import { Subject, Subscription } from 'rxjs';
import { ProfileCardComponent } from '../../../common/profile-card/profile-card.component';
import { SearchService } from '../../../services/search-service.service';
import { SortService } from '../../../services/sort.service';
import { SortByCategory } from '../../../../../common/constants';
import { GenderToggleService } from '../../../services/gender-toggle.service';
import { BasePage } from '../../page';

@Component({
  selector: 'shdchn-profiles-page',
  templateUrl: './profiles-page-mobile.component.html',
  styleUrl: './profiles-page-mobile.component.scss',
  animations: [
    trigger('fadeInSlideIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(-20px)' // Slide in from slightly to the left
        })
      ),
      transition(':enter, :leave', [
        animate('300ms ease-out') // Fade in and slide in quickly
      ])
    ]),
    trigger('fadeInOut', [
      state(
        '*',
        style({
          opacity: 1
        })
      ),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class ProfilesPageMobileComponent
  extends BasePage
  implements OnInit, OnDestroy
{
  @ViewChildren(ProfileCardComponent)
  profileCards: QueryList<ProfileCardComponent>;

  profiles: Profile[] = [];
  profilesFiltered: Profile[] = [];
  showFreeTextSearch = false;
  searchTerm = '';

  isCollapsed = false;
  isLoading = false;

  isImageModalOpen = false;
  imageModalTitle = '';
  imageModalImages = [];

  private searchInputSubject = new Subject<string>();

  // Subscriptions
  searchServiceSubscription: Subscription;
  sortServiceSubscription: Subscription;
  genderServiceSubscription: Subscription;

  constructor(
    private genderService: GenderToggleService,
    private searchService: SearchService,
    private sortService: SortService,
    private shadchanService: ShadchanService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.shadchanService.getProfiles().subscribe((p) => {
      this.profiles = p;
      this.profilesFiltered = p;
      this.isLoading = false;
    });

    this.searchServiceSubscription = this.searchService.searchEvent.subscribe(
      (e) => {
        if (e.type === 'cancel') {
          this.profilesFiltered = this.profiles;
        }
        if (e.type === 'submit') {
          this.shadchanService
            .generalSearch(e.data)
            .subscribe((results) => (this.profilesFiltered = results));
        }
      }
    );

    this.sortServiceSubscription = this.sortService.sortEvent.subscribe((e) => {
      this.sortProfiles(e.category, e.direction);
    });

    this.genderServiceSubscription =
      this.genderService.genderToggleEvent.subscribe((e) => {
        this.refilterProfilesByGender(e.category);
      });
  }

  ngOnDestroy(): void {
    this.searchInputSubject.complete(); // Complete the subject to avoid memory leaks
    this.genderServiceSubscription.unsubscribe();
    this.searchServiceSubscription.unsubscribe();
    this.sortServiceSubscription.unsubscribe();
  }

  toggleSearchInput() {
    this.showFreeTextSearch = !this.showFreeTextSearch;
  }

  onSearchInputChange(): void {
    this.searchInputSubject.next(this.searchTerm);
  }

  openSearchModal() {}

  showImageModal(profileId: number) {
    const profile = this.findProfileById(profileId);
    if (profile) {
      this.isImageModalOpen = true;
      this.imageModalTitle = profile.first_name + ' ' + profile.last_name;
      this.imageModalImages = [
        profile.profile_photo_url,
        ...(profile.photo_urls ?? [])
      ];
    }
  }

  sortProfiles(sortCategory: SortByCategory, direction: 'asc' | 'dsc') {
    this.profilesFiltered = this.profilesFiltered.sort((a, b) => {
      if (sortCategory === 'name') {
        const lastNameComparison = a.last_name.localeCompare(b.last_name);

        // If last names are the same, compare first names
        if (lastNameComparison === 0) {
          return direction === 'asc'
            ? a.first_name.localeCompare(b.first_name)
            : b.first_name.localeCompare(a.first_name);
        }

        // Otherwise, return comparison result based on direction
        return direction === 'asc' ? lastNameComparison : -lastNameComparison;
      } else if (sortCategory === 'age') {
        // Convert date_of_birth to Date objects for comparison
        const dateA = new Date(a.date_of_birth);
        const dateB = new Date(b.date_of_birth);

        // Compare dates
        if (dateA < dateB) {
          return direction === 'asc' ? -1 : 1;
        } else if (dateA > dateB) {
          return direction === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }

  hideImageModal() {
    this.isImageModalOpen = false;
  }

  toggleCollapseAllCards(): void {
    this.isCollapsed = !this.isCollapsed;
    this.profileCards.forEach((card) => card.toggleCardExpansion());
  }

  private findProfileById(profileId: number) {
    return this.profiles.find((p) => p.id === profileId);
  }

  private refilterProfilesByGender(genderMode: 'male' | 'female' | 'all') {
    if (genderMode === 'all') {
      this.profilesFiltered = this.profiles;
    } else {
      this.profilesFiltered = this.profiles.filter(
        (profile) => profile.gender === genderMode || !profile.gender
      );
    }
  }
}
