<auth-common-template-shell [errorMsg]="errorMsg" [isLoading]="isLoading">
  <form
    nz-form
    [formGroup]="authForm"
    (submit)="submit()"
    [@animateStagger]="{ value: '100' }"
  >
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Your username">
        <nz-input-group nzSize="large">
          <input nz-input placeholder="Username" formControlName="username" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <auth-common-button
      [@animate]="{ value: '*', params: { y: '100%' } }"
      [buttonText]="'Send Recovery Link'"
      [links]="['sign-in']"
    ></auth-common-button>
  </form>
</auth-common-template-shell>
