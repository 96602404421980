<nz-card
  style="max-width: 100px"
  [nzCover]="coverTemplate"
  [nzActions]="[actionSetting, actionEdit, actionEllipsis]"
>
  <nz-card-meta
    nzTitle="{{ profileData.last_name + ', ' + profileData.first_name }}"
    nzDescription="This is the description"
    [nzAvatar]="avatarTemplate"
  ></nz-card-meta>
</nz-card>

<ng-template #avatarTemplate>
  <nz-avatar [src]="profileData.profile_photo_url"></nz-avatar>
</ng-template>
<ng-template #coverTemplate>
  <div class="cover-image-container">
    <img alt="example" [src]="profileData.profile_photo_url" />
  </div>
</ng-template>
<ng-template #actionSetting>
  <span nz-icon nzType="setting"></span>
</ng-template>
<ng-template #actionEdit>
  <span nz-icon nzType="edit"></span>
</ng-template>
<ng-template #actionEllipsis>
  <span nz-icon nzType="ellipsis"></span>
</ng-template>

<!-- 
<nz-card
  nzTitle="{{ profileData.last_name + ', ' + profileData.first_name }}"
  [nzActions]="[datingStatus]"
  style="margin-bottom: 10px; width: 100%"
  [nzExtra]="titleBarTemplate"
  [nzHoverable]="true"
>
  <nz-card-meta
    nzTitle="{{ profileData.last_name + ', ' + profileData.first_name }}"
    nzDescription="This is the description"
    [nzAvatar]="avatarTemplate"
  ></nz-card-meta>

  <div class="profile-card" [@changeHeight]="isExpanded ? 'open' : 'closed'">
    <div class="profile-photo-container">
      <nz-carousel nzDotPosition="left">
        <div nz-carousel-content>
          <img
            alt="profile-image"
            class="profile-photo"
            [src]="profileData.profile_photo_url"
          />
        </div>
        <div nz-carousel-content>
          <img
            alt="profile-image"
            class="profile-photo"
            [src]="profileData.profile_photo_url"
          />
        </div>
        <div nz-carousel-content>
          <img
            alt="profile-image"
            class="profile-photo"
            [src]="profileData.profile_photo_url"
          />
        </div>
      </nz-carousel>
    </div>

    <div class="profile-photo-container">
      <img
        width="272"
        alt="profile-image"
        class="profile-photo"
        [src]="profileData.profile_photo_url"
      />
    </div>

    <div class="profile-details">
      <!-- <nz-card nzType="inner">
        <div nz-card-grid [ngStyle]="gridStyle">
          {{ profileData.date_of_birth }}
        </div>
        <span nz-icon nzType="home" nzTheme="outline"></span>
        <div nz-card-grid [ngStyle]="gridStyle">{{ profileData.city }}</div>
        <div nz-card-grid [ngStyle]="gridStyle">{{ profileData.country }}</div>
        <div nz-card-grid [ngStyle]="gridStyle">
          {{ profileData.occupation }}
        </div>
        <div
          nz-card-grid
          [ngStyle]="gridStyle"
          *ngFor="let religiousCategory of profileData.religious_categories"
        >
          {{ religiousCategory }}
        </div>
      </nz-card>

      <div class="vital-data">
        <div class="vital-data-item">
          <span nz-icon nzType="calendar" nzTheme="outline"></span>
          <div class="data">
            {{ profileData.date_of_birth | date : 'yyyy-MM-dd' }}
          </div>
        </div>
        <div class="vital-data-item">
          <span nz-icon nzType="home" nzTheme="outline"></span>
          <div class="data">{{ profileData.city }}</div>
        </div>
        <div class="vital-data-item">
          <span nz-icon nzType="global" nzTheme="outline"></span>
          <div class="data">{{ profileData.country }}</div>
        </div>
        <div class="vital-data-item">
          <span nz-icon nzType="tool" nzTheme="outline"></span>
          <div class="data">{{ profileData.occupation }}</div>
        </div>
        <div class="vital-data-item">
          <span nz-icon nzType="eye" nzTheme="outline"></span>
          <ng-container
            *ngFor="let religious_category of profileData.religious_seeking"
          >
            <div class="data">{{ religious_category.category_name }}</div>
          </ng-container>
        </div>
      </div>

      <div class="profile-description">
        <p>
          {{ profileData.description }}
        </p>
      </div>
      <div class="profile-notes">
        <p>(notes)</p>
      </div>
    </div>

    <div>
      <p></p>
    </div>
  </div>
</nz-card>

<ng-template #titleBarTemplate>
  <a (click)="goToProfile()"
    ><span nz-icon nzType="export" nzTheme="outline"></span
  ></a>
  <a (click)="toggleCardExpansion()" style="margin-left: 0.5rem">
    <span *ngIf="isExpanded" nz-icon nzType="shrink" nzTheme="outline"></span>
    <span
      *ngIf="!isExpanded"
      nz-icon
      nzType="fullscreen"
      nzTheme="outline"
    ></span>
  </a>
</ng-template>

<ng-template #expandCard> </ng-template>

<ng-template #avatarTemplate>
  <nz-avatar [nzSrc]="avatarPath"></nz-avatar>
</ng-template>

<ng-template #datingStatus>
  <nz-avatar-group>
    <nz-avatar nzIcon="user" nzSrc="/assets/man.jpeg"></nz-avatar>
    <nz-avatar style="background-color: #f50000" nzText="DR"></nz-avatar>
  </nz-avatar-group>
</ng-template> -->
