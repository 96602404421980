import { VERSION } from './version';

export const environment = {
  env_name: 'dev-live',
  version: VERSION,
  production: false,
  // baseUrl: 'https://ed14-69-125-117-31.ngrok-free.app',
  // baseUrl: 'http://192.168.1.107:4000',
  baseUrl: 'https://ishadchan.com',
  // baseUrl: 'http://localhost:4000',
  apiUrl: '/api',
  rapidKey: '879478796dmshb0716494a79e213p1b1a9djsne935e53efc39'
};
