import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { IUserState } from './../../../types/auth.d';
import {
  HttpResponse,
  HttpResponseBody,
  UserType
} from './../../../types/misc';
import { LocalStorageService } from './local-storage.service';
import {
  RegisterUserRequestBody,
  RegisterUserResponseBody
} from '../../../types/user';

const SHDCHN_USERSTATE_KEY = 'shdchnuserstate';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userState$ = new BehaviorSubject<IUserState | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  register(payload: RegisterUserRequestBody) {
    return this.http.put<HttpResponse<RegisterUserResponseBody>>(
      environment.baseUrl + '/auth/register',
      payload
    );
  }

  resetPassword(username: string, password: string, token: string) {
    return this.http.post<HttpResponse<any>>(
      environment.baseUrl + '/auth/reset-password',
      {
        username,
        password,
        token
      }
    );
  }

  forgotPassword(username: string) {
    return this.http.post<HttpResponse<any>>(
      environment.baseUrl + '/auth/forgot-password',
      {
        username
      }
    );
  }

  reverify(username: string) {
    return this.http.post<HttpResponse<any>>(
      environment.baseUrl + '/auth/reverify',
      {
        username: username.toLocaleLowerCase()
      }
    );
  }

  verify(username: string, verificationCode: string) {
    return this.http.post<HttpResponse<any>>(
      environment.baseUrl + '/auth/verify',
      {
        username: username.toLocaleLowerCase(),
        verification_code: verificationCode
      }
    );
  }

  signIn(username: string, password: string): Observable<IUserState> {
    // FIRST SURE NO OLD TOKEN IS HANGING AROUND
    this.localStorageService.deleteFromLocalStorage(SHDCHN_USERSTATE_KEY);
    return this.http
      .post<HttpResponse<any>>(environment.baseUrl + '/auth/sign-in', {
        username: username.toLocaleLowerCase(),
        password
      })
      .pipe(
        map((loginResponse: any) => {
          let userState: IUserState;
          if (
            'verified_at' in loginResponse.data &&
            loginResponse.data.verified_at !== null
          ) {
            // Set the user type as null
            loginResponse.data.user_type = null;
            userState = {
              isLoggedIn: false,
              userType: null,
              username: username,
              sessionId: null
            };
          } else {
            userState = {
              isLoggedIn: true,
              userType: loginResponse.data.user_type,
              username: username,
              sessionId: loginResponse.data.session_id
            };
            this.localStorageService.storeInLocalStorage(
              SHDCHN_USERSTATE_KEY,
              userState
            );
            this.userState$.next(userState);
          }
          return userState;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return throwError(err.error.message);
        })
      );
  }

  signOut() {
    this.http.post(environment.baseUrl + '/auth/sign-out', {}).subscribe(() => {
      this.localStorageService.deleteFromLocalStorage(SHDCHN_USERSTATE_KEY);
      this.userState$.next(null);
      this.router.navigate(['/']);
    });
  }

  loadUserState() {
    this.userState$.next(this.getUserState());
  }

  getUserState$() {
    return this.userState$;
  }

  private getUserState(): IUserState | null {
    return this.localStorageService.getFromLocalStorage<IUserState>(
      SHDCHN_USERSTATE_KEY
    );
  }
}
