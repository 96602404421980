import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthPage } from '../auth-page';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { absAnimations } from '../../animations';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['../auth-common.scss', './forgot-password-page.component.scss'],
  providers: [{ provide: AuthPage, useExisting: ForgotPasswordPageComponent }],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class ForgotPasswordPageComponent extends AuthPage implements OnInit {
  constructor(protected injector: Injector, private formBuilder: FormBuilder) {
    super(injector);
  }

  ngOnInit(): void {
    this.checkUserState();

    this.authForm = this.formBuilder.group({
      username: new FormControl('', Validators.required)
    });
  }

  submit() {
    this.authService
      .forgotPassword(this.authForm.value.username)
      .subscribe((result) => {
        if (!!result) {
          this.toastSvc.success(
            'Reset Password Link Sent to your email address'
          );
          this.router.navigate(['../sign-in'], {
            relativeTo: this.router.routerState.root
          });
        } else {
          this.toastSvc.fail('Unable to send Reset Password Link');
        }
      });
  }
}
