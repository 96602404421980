import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NavBarEventService } from '../../services/nav-bar-event.service';
import { Router } from '@angular/router';

@Component({
  template: ''
})
export abstract class AppPageComponent<DataType = any>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('actionPopover') actionPopover: TemplateRef<any>;
  pageData: DataType;
  isPageDataLoaded = false;

  activeTabIndex = 1;
  isEditMode = false;

  private navBarService: NavBarEventService;
  private router: Router;

  constructor(injector: Injector) {
    this.navBarService = injector.get(NavBarEventService);
    this.router = injector.get(Router);
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.navBarService.setActionPopover(this.actionPopover);
  }

  ngOnDestroy(): void {
    this.navBarService.setActionPopover(null);
  }

  // TODO fix ExpressionChangedAfterItHasBeenCheckedError error on tab change
  onTabChange(event: { index: number }) {
    // console.log('onTabChange', event, this.activeTabIndex);
    // this.activeTabIndex = event.index;
  }
  onTabClick(event: { index: number }) {
    // console.log('onTabClick', event, this.activeTabIndex);
    // this.activeTabIndex = event.index;
  }

  // TODO currently unused.
  async validatePage(fn: () => Promise<void>) {
    try {
      await fn();
    } catch (error) {
      console.error('Page Validation Error', error);
      this.ngOnDestroy();
      this.router.navigate(['/']);
    }
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
}
