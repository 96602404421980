import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { ShadchanService } from '../services/shadchan.service';
import { environment } from '../../../../environments/environment';
import { ShadchanNlpService } from '../services/shdchn-nlp.service';
import { Profile } from '../../../../../types/profile';
import { ToastService } from 'ng-zorro-antd-mobile';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKey } from '../../../../types-frontend/misc';

@Component({
  selector: 'shchn-new-profile-mobile',
  templateUrl: './new-profile-mobile.component.html',
  styleUrl: './new-profile-mobile.component.scss'
})
export class NewProfileMobileComponent implements OnInit, OnDestroy {
  isLoading = false;
  entryModeIndex = 0;
  entryMode: 'form' | 'freetext' = 'form';
  religiousCategories: string[] = [
    'Yeshivishe',
    'Modern',
    'Modern-Machmir',
    'Chasidishe'
  ]; // Add more religious categories as needed

  maritalStatus: string[] = ['single', 'widowed', 'divorced'];

  fileUploadUrl: string =
    environment.baseUrl + environment.apiUrl + '/shadchan/image-upload';
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  filePublicIds: string[] = [];

  isSubmittingNewProfileForm = false;

  selectedReligiousCategories = [...this.religiousCategories];

  freetext = '';
  profileData = null;

  constructor(private shdchnNlpService: ShadchanNlpService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onChangeEntryMode(event: { selectedIndex: number; value: string }) {
    if (event.value === 'By Form') {
      this.entryMode = 'form';
    } else if (event.value === 'By Text') {
      this.entryMode = 'freetext';
    }
  }

  submitTextProfile() {
    this.isLoading = true;
    this.shdchnNlpService.submitRawText(this.freetext).subscribe((result) => {
      // this.populateCreateProfileForm(result);
      this.profileData = result;
      this.entryMode = 'form';
      this.entryModeIndex = 0;
      this.isLoading = false;
    });
  }

  updateReligiousCategories(category: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedReligiousCategories.includes(category)) {
        this.selectedReligiousCategories.push(category);
      }
    } else {
      if (this.selectedReligiousCategories.includes(category)) {
        const index = this.selectedReligiousCategories.indexOf(category);
        if (index !== -1) {
          this.selectedReligiousCategories.splice(index, 1);
        }
      }
    }
  }
}
